@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&family=Poppins:wght@400;500&family=Roboto:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Karla:wght@400;500;600;700&family=Poppins:wght@400;500&display=swap');
:root {
  --nigh-color: #1e1e21;
  --day-color: #d5d5d7;
  --textNight-color: #d1dbfaae;
  --textDark-color: #222222;
  --selected-color: #0dcaf0;
  /* --selected-color: #87032d; */
  --pane-padding: 5px 42px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
html{
  scroll-behavior: smooth !important;
}

body {
  background: var(--nigh-color);
  color: var(--day-color);
  font-family: "Roboto", sans-serif;
}

/* Header */



/* Hero */

.hero {
  padding-top: 110px !important;

}
.about{
  padding-top: 110px !important;
  padding-bottom: 100px;
}

.work{
  padding-top: 110px !important;
}
.contact{
  padding-top: 110px !important;
  padding-bottom: 100px;
}

.page__title{
  font-size: 2rem;
  font-family: 'Karla', sans-serif;
}
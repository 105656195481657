.hero {
  height: 50em;
}
.row__section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content {
  height: 300px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 10px;

  transition: 0.3s ease-in-out;
}

.titleCont span {
  color: transparent;
  animation: blur 9s ease-in-out forwards;
  -webkit-animation: blur 3s ease-in-out forwards;
}
.titleCont span:nth-child(1) {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

.titleCont span:nth-child(3) {
  animation-delay: .4s;
  -webkit-animation-delay: .4s;
}

.titleCont span:nth-child(5) {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}
.titleCont span:nth-child(7) {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
@keyframes blur {
  0%		{text-shadow:  0 0 100px #ffffff73; opacity:0.5;}
  /* 5%		{text-shadow:  10px 10px 20px #ffffffa5;}
	15%		{opacity: .2;}
	20%		{text-shadow:  0 0 0px #ffffff85;}
	80%		{text-shadow:  0 0 0px #ffffff80;}
	85%		{opacity: .9;}
	95%		{text-shadow:  10px 10px 50px #ffffff5c;} */
	100%	{text-shadow:  0px 0px 0px #7d7d7d; opacity:1;}
}
@-webkit-keyframes blur {
	0%		{text-shadow:  0 0 100px #fff; opacity:0.1;}
	5%		{text-shadow:  0 0 90px #fff;}
	15%		{opacity: .6;}
	20%		{text-shadow:  0 0 0px #fff;}
	80%		{text-shadow:  0 0 0px #8d8b8b61;}
	85%		{opacity: 1;}
	95%		{text-shadow:  0 0 90px #7776766a;}
	100%	{text-shadow:  0 0 0px #9494947b; opacity:1;}
}

/* @keyframes blur {
	0%		{text-shadow:  0 0 100px #ffffff73; opacity:0.5;}
	5%		{text-shadow:  10px 10px 20px #ffffffa5;}
	15%		{opacity: .2;}
	20%		{text-shadow:  0 0 0px #ffffff85;}
	80%		{text-shadow:  0 0 0px #ffffff80;}
	85%		{opacity: .9;}
	95%		{text-shadow:  10px 10px 50px #ffffff5c;}
	100%	{text-shadow:  10px 10px 20px #fff; opacity:1;}
} */

/* @-webkit-keyframes blur {
	0%		{text-shadow:  0 0 100px #fff; opacity:0.5;}
	5%		{text-shadow:  0 0 90px #fff;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 0px #fff;}
	80%		{text-shadow:  0 0 0px #fff;}
	85%		{opacity: 1;}
	95%		{text-shadow:  0 0 90px #fff;}
	100%	{text-shadow:  0 0 100px #fff; opacity:1;}
} */

section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main-content {
  font-family: "Caveat", cursive;

  padding: 10px 10px;
  border-radius: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
  cursor: pointer;
  transition: 1s ease-in-out;
}

.main-title {
  font-size: 2rem;
}
.main-content .titleCont__author {
  font-size: 0.8rem;
}

.hero__img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prof__img {
  background-repeat: no-repeat;
  background-size: contain !important;
  border-radius: 20%;
  width: 180px;
  height: 180px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.prof__img:hover {
  border-radius: 50%;
}
